<template>
  <div class="box_1">
    <div class="header">
      <div class="text" @click="goToMenuPage()">{{ pageTitle }}</div> 
    </div>

    <div class="content_box cursor" @click="goToMenuPage()">
      <div class="chart_box">
        <zhu-zhaung-tu :value="data1" />
      </div>
    </div>
  </div>
</template>

<script>
  import ZhuZhaungTu from '../charts/zhu-zhuang-tu.vue'
  
  export default {
    name: 'showPage1',
    components: { ZhuZhaungTu, },
    data() {
      return {
        activeIndex: 0,

        pageTitle: '',

        data1: {},
      }
    },
    mounted() {
      this.pageTitle = this.$route.params.val
      if (this.pageTitle == '巴林右旗机械化统计') {
        this.data1 = {
          title: {
            text: "机械化统计",
            left: "20px",
            top: '0%',
            textStyle: {
              color: "#fff"
            }
          },
          yName: '辆',
          x: ['小型拖拉机', '大型拖拉机', '收割机', '播种机', '打草机'],
          y: [7334, 3248, 7834, 6323, 7643, 9234]
        }
      }

      if (this.pageTitle == '巴林右旗大板镇机械化统计') {
        this.data1 = {
          title: {
            text: "机械化统计",
            left: "20px",
            top: '0%',
            textStyle: {
              color: "#fff"
            }
          },
          yName: '辆',
          x: ['小型拖拉机', '大型拖拉机', '收割机', '播种机', '打草机'],
          y: [1443, 284, 986, 856, 540, 598]
        }
      }
    },
    methods: {
      // 跳转到 menu 页面
      goToMenuPage() {
        this.$router.back()
        // this.$router.push({
        //   path: "/bigscreen/menuPage"
        // })
      },
    }
  }
</script>



<style scoped lang="less">
  .activeColor { color: #2366fc !important; }

  .box_1 {
    width: 100%;
    height: 100%;
    padding: 10px;

    .header {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;

      .text {
        width: 50%;
        height: 40px;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        // background-color: #2366fc;
        margin: 0 auto;
      }
    }

    .content_box {
      width: 100%;
      height: calc(100% - 50px);
      margin-top: 10px;
      // background-color: #2366fc;
      // display: flex;
      // flex-direction: row;
      // justify-content: space-around;
      padding: 10px;
      position: relative;

      .chart_box {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  @media screen and (min-width: 1800px) {
    .chart_box { margin-top: 50px; }
  }

  @media screen and (min-width: 1600px) and (max-width: 1799px) {
    .chart_box { margin-top: 40px; }
  }

  @media screen and (min-width: 1400px) and (max-width: 1599px) {
    .chart_box { margin-top: 30px; }
  }

  @media screen and (min-width: 1200px) and (max-width: 1399px) {
    .chart_box { margin-top: 30px; }
  }
</style>